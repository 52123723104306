import Page from "@/components/template-parts/page";
import STKButton from "@/components/common/button";
// TRANSLATE:
const Handler = () => {
    return(
        <Page title="Etsimääsi sivua ei löytynyt." type="default" loading={false} >
            <div>
                <h1 className="h4 mb-6">Hups! Etsimääsi sivua ei löytynyt.</h1>
                <STKButton href="/">
                    Aloitus
                </STKButton>
            </div>
        </Page>
    )
}

export default Handler;